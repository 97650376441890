/* jshint ignore:start */
(function ($) {
    // global var
    var global_map  = null,
        infoWindows = [],
        markers     = [],
        loader      = '<div class="loader"><img width="32" height="32" src="/wp-content/themes/midas/dist/images/ajax-loader.gif"></div>';

    function showFormLoader (e) {
        $(e).block({
            message   : loader,
            css       : {
                width       : '32px',
                height      : '32px',
                border      : 'none',
                borderRadius: '10px',
                left        : '48%',
                'z-index'   : '2000'
            },
            baseZ     : 15,
            overlayCSS: {
                backgroundColor: 'transparent',
                opacity        : 0.5,
                cursor         : 'wait'
            }
        });
    }

    /*
   *  center_map
   *
   *  This function will center the map, showing all markers attached to this map
   *
   *  @type	function
   *  @date	8/11/2013
   *  @since	4.3.0
   *
   *  @param	map (Google Map object)
   *  @return	n/a
   */
    function center_map (map) {

        // vars
        var bounds = new google.maps.LatLngBounds();

        // loop through all markers and create bounds
        $.each(map.markers, function (i, marker) {

            var latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());

            bounds.extend(latlng);

        });

        // only 1 marker?
        if (map.markers.length === 1) {
            // set center of map
            map.setCenter(bounds.getCenter());
            map.setZoom(16);
        } else {
            // fit to bounds
            map.fitBounds(bounds);
        }

    }

    /*
    *  add_marker
    *
    *  This function will add a marker to the selected Google Map
    *
    *  @type	function
    *  @date	8/11/2013
    *  @since	4.3.0
    *
    *  @param	$marker (jQuery element)
    *  @param	map (Google Map object)
    *  @return	n/a
    */
    function add_marker ($marker, map) {

        // var
        var latlng = new google.maps.LatLng($marker.attr('data-lat'), $marker.attr('data-lng'));

        // create marker
        var marker = new google.maps.Marker({
            position: latlng,
            map     : map,
            icon    : {
                url : '/wp-content/themes/midas/dist/images/map-pin.png',
                size: new google.maps.Size(70, 54)
            }
        });

        // add to array
        map.markers.push(marker);

        // if marker contains HTML, add it to an infoWindow
        if ($marker.html()) {
            // create info window
            var infoWindow = new google.maps.InfoWindow({
                content		: $marker.html()
            });

            infoWindows.push(infoWindow);
            markers.push(marker);

            var marker_index = markers.length;

            // show info window when marker is clicked
            google.maps.event.addListener(marker, 'click', function() {

                //close all
                for (var i = 0; i < infoWindows.length; i++) {
                    infoWindows[i].close();
                }

                infoWindow.open( map, marker );

                $('.result-listing .result-list').removeClass('active');
                $('*[data-index="' + ( marker_index - 1 ) + '"]').parent().addClass('active');
            });
        }

    }

    /*
    *  new_map
    *
    *  This function will render a Google Map onto the selected jQuery element
    *
    *  @type	function
    *  @date	8/11/2013
    *  @since	4.3.0
    *
    *  @param	$el (jQuery element)
    *  @return	n/a
    */
    function new_map ($el) {

        // var
        var $markers = $el.find('.marker');

        // vars
        var args = {
            zoom     : 16,
            center   : new google.maps.LatLng(0, 0),
            mapTypeId: google.maps.MapTypeId.ROADMAP
        };

        // create map
        var map = new google.maps.Map($el[0], args);

        // add a markers reference
        map.markers = [];
        markers = [];

        // add markers
        $markers.each(function () {

            add_marker($(this), map);

        });

        // center map
        center_map(map);

        // return
        return map;

    }

    /**
     * Render map
     */
    function acf_map () {
        $('.acf-map').each(function () {
            global_map = new_map($(this));
        });
    }

    /**
     * Locate stores
     *
     * @param lat
     * @param lng
     */
    function locate_stores (lat, lng) {
        var result_listing     = $('.result-listing'),
            result_listing_map = $('.acf-map'),
            data               = {
                lat   : lat,
                lng   : lng,
                radius: $('#radius').val(),
                action: $('#action').val()
            };

        $.ajax({
            url       : ajax.url,
            type      : 'GET',
            data      : data,
            dataType  : 'json',
            beforeSend: function () {
                showFormLoader('.search-locations');
            },
            success   : function (html) {
                $('.show-all-stores').removeClass('d-none');

                result_listing.html(html.result_listing);

                if (html.result_listing_map) {
                    result_listing_map.html(html.result_listing_map);
                    acf_map();
                } else {
                    result_listing_map.html(
                        '<div class="alert alert-warning" role="alert">No stores nearby found. Please widen your search query.</div>'
                    );
                }

                var marker = new google.maps.Marker({
                    map     : global_map,
                    position: new google.maps.LatLng(lat, lng),
                    icon    : null
                });

                var circle = new google.maps.Circle({
                    map         : global_map,
                    radius      : $('#radius').val() * 1000,
                    fillColor   : '#e41e26',
                    strokeWeight: 0
                });

                marker.setAnimation(google.maps.Animation.BOUNCE);
                circle.bindTo('center', marker, 'position');

                global_map.setZoom(12);
                global_map.setCenter(marker.getPosition());

                $('.result-listing').animate({
                    scrollTop : 0
                }, 1000);
            },
            complete  : function () {
                $('.search-locations').unblock();
            }
        });
    }

    /**
     * Locate nearest stores
     *
     * @param lat
     * @param lng
     */
    function locate_nearest_stores (lat, lng) {
        var result_listing     = $('.result-listing'),
            result_listing_map = $('.acf-map'),
            data               = {
                lat   : lat,
                lng   : lng,
                action: $('#use-my-location').data('action')
            };

        $.ajax({
            url       : ajax.url,
            type      : 'GET',
            data      : data,
            dataType  : 'json',
            beforeSend: function () {
                showFormLoader('.search-locations');
            },
            success   : function (html) {
                $('.show-all-stores').removeClass('d-none');

                result_listing.html(html.result_listing);

                if (html.result_listing_map) {
                    result_listing_map.html(html.result_listing_map);
                    acf_map();
                } else {
                    result_listing_map.html(
                        '<div class="alert alert-warning" role="alert">No stores nearby found. Please widen your search query.</div>'
                    );
                }

                $('.result-listing').animate({
                    scrollTop : 0
                }, 1000);
            },
            complete  : function () {
                $('.search-locations').unblock();
            }
        });
    }

    /**
     * Locate states
     */
    function locate_states () {
        var result_listing     = $('.result-listing'),
            result_listing_map = $('.acf-map'),
            dd                 = $('#filter-dd'),
            data               = {
                action: dd.data('action'),
                state : dd.find('option:selected').text()
            };

        $.ajax({
            url       : ajax.url,
            type      : 'GET',
            data      : data,
            dataType  : 'json',
            beforeSend: function () {
                showFormLoader('.search-locations');
            },
            success   : function (html) {
                $('.show-all-stores').removeClass('d-none');

                result_listing.html(html.result_listing);

                if (html.result_listing_map) {
                    result_listing_map.html(html.result_listing_map);
                    acf_map();
                } else {
                    result_listing_map.html(
                        '<div class="alert alert-warning" role="alert">No stores nearby found. Please widen your search query.</div>'
                    );
                }

                $('.result-listing').animate({
                    scrollTop : 0
                }, 1000);
            },
            complete  : function () {
                $('.search-locations').unblock();
            }
        });
    }

    /**
     * Initialize Auto Complete
     *
     * This function will render auto complete search input
     */
    function initialize () {
        var lat     = null,
            lng     = null,
            input   = document.getElementById('search-input'),
            options = {
                types                : ['(regions)'],
                componentRestrictions: {
                    country: ['au']
                }
            };

        var geocoder = new google.maps.Geocoder();

        if (input !== null) {
            var autocomplete = new google.maps.places.Autocomplete(input, options);

            google.maps.event.addListener(autocomplete, 'place_changed', function () {
                var completeAddressValue = input.value;
                var suburb,state;
                $('#acf-map-retailer-branches-search-input').removeClass('has-error');
                place =  autocomplete.getPlace();
                for (var i = 0; i < place.address_components.length; i++) {
                  if(place.address_components[i].types[0] === "locality" || place.address_components[i].types[0] === "colloquial_area")
                  {
                    suburb = place['address_components'][i]['long_name'];
                  }
                  if(place.address_components[i].types[0] === "administrative_area_level_1")
                  {
                    state = place['address_components'][i]['short_name'];
                  }
                }
                console.log(suburb);
                console.log(place.address_components);
                $(input).val(suburb);
                geocoder.geocode({
                        'address': completeAddressValue,
                        componentRestrictions: {
                            country: 'AU'
                        }
                    },
                    function (results, status) {
                        if (status === google.maps.GeocoderStatus.OK) {
                            lat = results[0].geometry.location.lat();
                            lng = results[0].geometry.location.lng();
                            $('#search-lat').val(lat);
                            $('#search-lng').val(lng);
                            $('#filter-dd').find('option[value=' + state + ']').prop('selected',true);
                            locate_stores(lat, lng);
                        } else if (status === google.maps.GeocoderStatus.ZERO_RESULTS) {
                            alert('Please enter a valid address.');
                        } else if (status === google.maps.GeocoderStatus.OVER_QUERY_LIMIT) {
                            alert('Query Limit: Over the qouta.');
                        } else if (status === google.maps.GeocoderStatus.REQUEST_DENIED) {
                            alert('Your request was denied.');
                        } else if (status === google.maps.GeocoderStatus.INVALID_REQUEST) {
                            alert('Invalid Request: Address, components or latlng is missing.');
                        } else if (status === google.maps.GeocoderStatus.UNKNOWN_ERROR) {
                            alert('Unknown Error: Please try again later.');
                        }
                    }
                );
            });
        }
    }

    /**
     * Open info window
     *
     * @param id
     */
    function infoWindow (id) {
        google.maps.event.trigger(markers[id], 'click');
    }

    $(document).ready(function () {
        acf_map();
        google.maps.event.addDomListener(window, 'load', initialize);

        var uml = $('#use-my-location');
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
                uml.data('lat', position.coords.latitude).data('lng', position.coords.longitude);
            }, function () {
                uml.fadeOut();
            });
        } else {
            uml.fadeOut();
        }

        $('#filter-dd').change(function () {
            var el          = $(this),
                coordinates = el.val() ? el.val().split(',') : null;
                localStorage.setItem("filterDDVal", coordinates);
            if (coordinates) {
                var lat = coordinates[0],
                    lng = coordinates[1];

                $('#search-lat').val('');
                $('#search-lng').val('');
                $('#search-input').val('');

                locate_states();
            } else {
                $('.show-all-stores > a:first').trigger('click');
            }
        });

        $('#radius').change(function () {
            var el  = $(this).val(),
                lat = $('#search-lat').val(),
                lng = $('#search-lng').val();

            if (lat && lng) {
                locate_stores(lat, lng);
            }
        });

        $('#use-my-location').click(function () {
            showFormLoader('.search-locations');
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(function (position) {
                    var lat = position.coords.latitude,
                        lng = position.coords.longitude;

                    $('#filter-dd').find('option:first').attr('selected', 'selected');
                    $('#search-input').val('');
                    $('#search-lat').val(lat);
                    $('#search-lng').val(lng);

                    locate_nearest_stores(lat, lng);
                }, function () {
                    $('.search-locations').unblock();
                    alert('Could not get your location. Please make sure to allow location tracking.');
                });
            } else {
                $('.search-locations').unblock();
                alert('Could not get your location. Please make sure to allow location tracking.');
            }
        });

        $('#show-all-stores').click(function (e) {
            e.preventDefault();

            var result_listing     = $('.result-listing'),
                result_listing_map = $('.acf-map'),
                data               = {
                    action: $(this).data('action')
                };

            $('#filter-dd').find('option:first').attr('selected', 'selected');
            $('#search-lat').val('');
            $('#search-lng').val('');
            $('#search-input').val('');

            $.ajax({
                url       : ajax.url,
                type      : 'GET',
                data      : data,
                dataType  : 'json',
                beforeSend: function () {
                    showFormLoader('.search-locations');
                },
                success   : function (html) {
                    $('.show-all-stores').addClass('d-none');

                    result_listing.html(html.result_listing);

                    if (html.result_listing_map) {
                        result_listing_map.html(html.result_listing_map);
                        acf_map();
                    } else {
                        result_listing_map.html(
                            '<div class="alert alert-warning" role="alert">No stores nearby found. Please widen your search query.</div>'
                        );
                    }

                    $('.result-listing').animate({
                        scrollTop : 0
                    }, 1000);
                },
                complete  : function () {
                    $('.search-locations').unblock();
                }
            });
        });
        var currentFilterDD = localStorage.getItem("filterDDVal");
        $('#filter-dd').val(currentFilterDD).change();
        $('.result-listing').on('click', '.info-window', function (e) {
            e.preventDefault();
            var latLng = new google.maps.LatLng($(this).data('lat'), $(this).data('lng'));
            infoWindow($(this).data('index'));
            global_map.panTo(latLng);
        });
    });

})(jQuery);
/* jshint ignore:end */
