/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
	var loader = '<div class="loader"><img width="32" height="32" src="/wp-content/themes/midas/dist/images/ajax-loader.gif"></div>';

	function showLoader(e) {
		$(e).block({
			message: loader,
			css: {
				width: '32px',
				height: '32px',
				border: 'none',
				borderRadius: '10px',
				left: '48%',
				'z-index': '2000'
			},
			baseZ: 15,
			overlayCSS: {
				backgroundColor: 'transparent',
				opacity: 0.5,
				cursor: 'wait'
			}
		});
	}

	var realWidth = {};
	realWidth.getWindowWidth = function () {
		var windowWidth = 0;
		if (typeof (window.innerWidth) === 'number') {
			windowWidth = window.innerWidth;
		} else {
			if (document.documentElement &&
				document.documentElement.clientWidth) {
				windowWidth = document.documentElement.clientWidth;
			} else {
				if (document.body && document.body.clientWidth) {
					windowWidth = document.body.clientWidth;
				}
			}
		}
		return windowWidth;
	};

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function () {
				// JavaScript to be fired on all pages
				var isIE11 = /Trident.*rv[ :]*11\./.test(navigator.userAgent);
				if (isIE11) {
					$('.js-bg-image').each(function () {
						var el = $(this);
						el.css('background-image', 'url(' + el.find('> img').attr('src') + ')');
					});
				}
				localStorage.removeItem('date_val');
				localStorage.removeItem('time_val');
				// add select2 for select gfields
				//$('.gfield_select').select2();
				$('.transform-select2').select2();
				$('.transform-select select').select2();
				var nScrollStart = 0;
				$(document).scroll(function () {
					nScrollStart = $(this).scrollTop();
					if (nScrollStart >= 720) {
						$('body').addClass('sticky');
					} else {
						$('body').removeClass('sticky');
					}
				});


				jQuery('.card-body').matchHeight();
				// Style the specific fields by using jqTransform
				$('.jqTransform-field').each(function () {
					$('.jqTransform-field').jqTransform();
				});

				// matchHeight
				$('.matchHeight').each(function () {
					$(this).find('.matchItem').matchHeight();
				});

				// Clearing Default Field Values with jQuery
				$.fn.cleardefault = function () {
					return this.focus(function () {
						if (this.value === this.defaultValue) {
							this.value = '';
						}
					}).blur(function () {
						if (!this.value.length) {
							this.value = this.defaultValue;
						}
					});
				};
				$('.clearit input, .clearit textarea').cleardefault();

				// displaying submenu onclick toggle start in tablet portarit down to mobile views
				$('.submenu').hide();
				$('span.submenu-trigger').click(function () {
					$(this).toggleClass('active').next().slideToggle('fast');
				});

				// displaying footer links onclick toggle in mobile views
				$('.footer-links').hide();
				$('h6.footer-title').click(function () {
					var windWidth = $(window).width();
					if (windWidth < 992) {
						$(this).toggleClass('active').next().slideToggle('fast');
					}
				});

				$(window).load(function () {
					// Make elements the same min-height
					$('.midas-business-portals .row-01 .mbp-content-inner').equalHeight(2);
					$('.midas-business-portals .row-02 .mbp-content-inner').equalHeight(2);

				});

				$(window).on('resize', function () {
					// Make elements the same min-height
					$('.midas-business-portals .row-01 .mbp-content-inner').equalHeight(2);
					$('.midas-business-portals .row-02 .mbp-content-inner').equalHeight(2);
				});

				jQuery.fn.exists = function () {
					return this.length > 0;
				};
				$('.video-modal').on('hide.bs.modal', function (e) {
					var $if = $(e.delegateTarget).find('iframe');
					var $vd = $(e.delegateTarget).find("#vid");
					var src = $if.attr("src");
					$if.attr("src", '/empty.html');
					$if.attr("src", src);
					if ($vd.exists()) {
						$vd.get(0).pause();
					}
				});

				/*
				* Replace all SVG images with inline SVG
				*/
				jQuery('img._svg').each(function () {
					var $img = jQuery(this);
					var imgID = $img.attr('id');
					var imgClass = $img.attr('class');
					var imgURL = $img.attr('src');

					jQuery.get(imgURL, function (data) {
						// Get the SVG tag, ignore the rest
						var $svg = jQuery(data).find('svg');

						// Add replaced image's ID to the new SVG
						if (typeof imgID !== 'undefined') {
							$svg = $svg.attr('id', imgID);
						}
						// Add replaced image's classes to the new SVG
						if (typeof imgClass !== 'undefined') {
							$svg = $svg.attr('class', imgClass + ' replaced-svg');
						}

						// Remove any invalid XML tags as per http://validator.w3.org
						$svg = $svg.removeAttr('xmlns:a');

						// Check if the viewport is set, if the viewport is not set the SVG wont't scale.
						if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
							$svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'));
						}

						// Replace image with new SVG
						$img.replaceWith($svg);

					}, 'xml');

				});
			},
			finalize: function () {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},
		// Home page
		'home': {
			init: function () {
				// JavaScript to be fired on the home page
				$('#auto-listing-make').change(function () {
					var data = {
						action: 'get_model',
						make: $(this).val()
					};

					$.ajax({
						url: ajax.url,
						type: 'GET',
						data: data,
						dataType: 'json',
						beforeSend: function () {
							showLoader('#booking-service-form');
						},
						success: function (option) {
							$('#auto-listing-model').html(option);
						},
						complete: function () {
							$('#booking-service-form').unblock();
						}
					});
				});


				// Home Banner Slider
				$('.home-banner .owl-carousel').on('initialized.owl.carousel', function (event) {

				}).owlCarousel({
					lazyLoad: true,
					autoplay: 3000,
					items: 1, // THIS IS IMPORTANT
					slideSpeed: 300,
					animateIn: 'fadeIn',
					animateOut: 'fadeOut',
					autoplayHoverPause: true, // Stops autoplay
					nav: true,
					dots: true,
					loop: true,
					singleItem: true,
					responsive: {
						0: { // breakpoint from 0 up - small smartphones
							nav: false
						},
						480: {  // breakpoint from 480 up - smartphones // landscape
							nav: false
						},
						768: { // breakpoint from 768 up - tablets
							nav: false,

						},
						992: { // breakpoint from 992 up - desktop
							nav: true,
						}
					}
				});

				// Home Content Slider
				$('.home-slider .owl-carousel').owlCarousel({
					autoplay: 5000,
					items: 1, // THIS IS IMPORTANT
					slideSpeed: 400,
					autoplayHoverPause: true, // Stops autoplay
					nav: true,
					dots: false,
					loop: true,
					singleItem: true
				});
                $('.midas-benefits-carousel-menu .owl-carousel').owlCarousel({
                    autoplay: true,
                    items: 1, // THIS IS IMPORTANT
                    slideSpeed: 400,
                    autoplayHoverPause: false, // Stops autoplay
                    nav: false,
                    dots: false,
                    loop: true,
                    singleItem: true
                });
				// Home Content Slider
				$('.faq-section .owl-carousel').owlCarousel({
					autoplay: 5000,
					items: 1, // THIS IS IMPORTANT
					slideSpeed: 400,
					autoplayHoverPause: true, // Stops autoplay
					nav: true,
					dots: false,
					loop: true,
					singleItem: true
				});
				$('.testimonials-section .owl-carousel').owlCarousel({
					autoplay: 5000,
					items: 1, // THIS IS IMPORTANT
					slideSpeed: 400,
					autoplayHoverPause: true, // Stops autoplay
					nav: true,
					dots: false,
					loop: true,
					singleItem: true
				});
				$('.featured-brands-section .owl-carousel').owlCarousel({
					autoplay: 5000,
					items: 5, // THIS IS IMPORTANT
					margin: 10,
					slideSpeed: 400,
					autoplayHoverPause: true, // Stops autoplay
					nav: true,
					dots: false,
					loop: true,
					responsive: [
						{
							breakpoint: 991,
							settings: {
								slidesPerRow: 2,
								rows: 2
							}
						},
						{
							breakpoint: 480,
							settings: {
								slidesPerRow: 1,
								rows: 1
							}
						}
					]
				});
				//home brand logo slider
				$('.slick-slider').slick({
					dots: false,
					autoplay: true,
					autoplaySpeed: 5000,
					slidesPerRow: 3,
					rows: 2,
					centerMode: true,
					variableWidth: true,
					prevArrow: $('.bl-prev'),
					nextArrow: $('.bl-next'),
					responsive: [
						{
							breakpoint: 991,
							settings: {
								slidesPerRow: 2,
								rows: 2
							}
						},
						{
							breakpoint: 480,
							settings: {
								slidesPerRow: 1,
								rows: 1
							}
						}
					]
				});
			},
			finalize: function () {
				$(window).on('load', function () {
					var hb_container = $('.hb-nearest-store');
					showLoader('.hb-nearest-store');

					if (navigator.geolocation) {
						navigator.geolocation.getCurrentPosition(function (position) {
							var data = {
								lat: position.coords.latitude,
								lng: position.coords.longitude,
								action: 'hb_get_nearest_store'
							};

							$.ajax({
								url: ajax.url,
								type: 'GET',
								data: data,
								dataType: 'json',
								success: function (html) {
									if (html) {
										hb_container.html(html);
									} else {
										hb_container.fadeOut();
									}
								},
								complete: function () {
									hb_container.unblock();
								}
							});

						}, function () {
							hb_container.fadeOut();
						});
					} else {
						hb_container.fadeOut();
					}
				});
			}
		},

		// Services page
		'automotive_services': {
			init: function () {
				// Services Carousel
				$('.services-carousel .owl-carousel').owlCarousel({
					autoplay: 5000,
					loop: true,
					margin: 25,
					nav: true,
					dots: false,
					autoplayHoverPause: true, // Stops autoplay
					responsive: {
						0: {
							items: 1
						},
						768: {
							items: 2
						},
						992: {
							items: 3
						}
					}
				});
			}
		},

		// Franchise page
		'page_franchise': {
			init: function () {
				jQuery('.fc-content').matchHeight();
				// Franchising Carousel
				$('.franchising-carousel .owl-carousel').owlCarousel({
					autoplay: 3000,
					loop: true,
					margin: 20,
					nav: true,
					dots: false,
					responsive: {
						0: {
							items: 1
						},
						768: {
							items: 2
						},
						992: {
							items: 3
						}
					}
				});
			}
		},

		// Page using Franchise Template
		'page_template_template_franchise': {
			init: function () {
				jQuery('.fc-content').matchHeight();
				// Franchising Carousel
				$('.franchising-carousel .owl-carousel').owlCarousel({
					autoplay: 3000,
					loop: true,
					margin: 20,
					nav: true,
					dots: false,
					responsive: {
						0: {
							items: 1
						},
						768: {
							items: 2
						},
						992: {
							items: 3
						}
					}
				});
			}
		},

		// Interest Free Payment Options page
		'page_interest_free_payments': {
			init: function () {
				$('a.trigger-link-tab').each(function (e) {
					$(this).click(function () {
						$('ul.nav-pills > li.active').removeClass('active open');
						$(this).parent('li').addClass('active open');
					});
				});

				$('.heading-wrap').each(function () {
					$(this).click(function () {
						$(this).toggleClass('active');
						$(this).parent().find('.content-wrap').slideToggle('fast');
					});
				});

				$('.acc-toggle').each(function () {
					$(this).click(function () {
						$(this).toggleClass('active-toggle');
						$(this).parent().find('.acc-toggle-mobile').slideToggle('fast');
					});
				});
			}
		},

		// Careers or Employment Opportunities page
		'page_employment_opportunities': {
			init: function () {
				// Career Testimonials Slider
				$('.career-testimonial-slider .owl-carousel').owlCarousel({
					autoplay: 3000,
					items: 1, // THIS IS IMPORTANT
					slideSpeed: 300,
					animateIn: 'fadeIn',
					animateOut: 'fadeOut',
					autoplayHoverPause: true, // Stops autoplay
					nav: true,
					dots: false,
					loop: true,
					singleItem: true
				});

				$('.filter-sidebar .widget h6').each(function () {
					$(this).click(function () {
						$(this).toggleClass('active');
						$(this).parent().find('.filter-list').slideToggle('fast');
					});
				});

				//reset filtering
				$('#reset-filter').click(function () {
					$(".jqTransformChecked").click();
				});

				//count job choices
				var jobLength = $('.filter-category a.jqTransformCheckbox').length;

				//job category filter group
				$('.filter-category a.jqTransformCheckbox').bind('click', function () {
					$('.no-results').addClass('d-none');
					$('.ajax-pagination').addClass('d-none');
					if ($(this).hasClass('clicked')) {
						$(this).removeClass('clicked');
						localStorage.setItem("clickedCheckboxJob", null);
						$('.initial-pagination .active .page-link').click();
					} else {
						$(".filter-category a.jqTransformChecked").not($(this)).click();
						$(this).addClass('clicked');
						var clickedIndexJob = $(this).index('.jqTransformCheckbox');
						localStorage.setItem("clickedCheckboxJob", clickedIndexJob);
						$('.initial-pagination .active .page-link').click();
					}
				});
				var activeIndexJob = localStorage.getItem("clickedCheckboxJob");
				if (activeIndexJob) {
					$('.filter-category a.jqTransformCheckbox').eq(activeIndexJob).click();
				}

				//location filter group
				$('.filter-location a.jqTransformCheckbox').bind('click', function () {
					$('.no-results').addClass('d-none');
					$('.ajax-pagination').addClass('d-none');
					if ($(this).hasClass('clicked')) {
						$(this).removeClass('clicked');
						localStorage.setItem("clickedCheckboxLoc", null);
						$('.initial-pagination .active .page-link').click();
					} else {
						$(".filter-location a.jqTransformChecked").not($(this)).click();
						$(this).addClass('clicked');
						var clickedIndexLoc = $(this).index('.jqTransformCheckbox');
						localStorage.setItem("clickedCheckboxLoc", clickedIndexLoc);
						$('.initial-pagination .active .page-link').click();
					}
				});
				var activeIndexLoc = localStorage.getItem("clickedCheckboxLoc");
				if (activeIndexLoc) {
					$('.filter-location a.jqTransformCheckbox').eq(activeIndexLoc - jobLength).click();
				}
			}
		},

		// Earn Velocity Points page
		'page_earn_velocity_points': {
			init: function () {
				// displaying submenu onclick toggle start in tablet portarit down to mobile views
				$('.evp-bottom-content').hide();
				$('.evp-bottom-header-title').click(function () {
					$(this).toggleClass('active').next().slideToggle('fast');
				});
			}
		},

		// Store Details
		'single_stores': {
			init: function () {
				// Store Slider
				$('.store-slider .owl-carousel').owlCarousel({
					autoplay: 3000,
					items: 1, // THIS IS IMPORTANT
					slideSpeed: 300,
					animateIn: 'fadeIn',
					animateOut: 'fadeOut',
					autoplayHoverPause: true, // Stops autoplay
					nav: false,
					dots: true,
					loop: true,
					singleItem: true
				});

				// Store Carousel
				$('.store-carousel .owl-carousel').owlCarousel({
					autoplay: 7000,
					loop: true,
					margin: 50,
					nav: true,
					dots: false,
					responsive: {
						0: {
							items: 1
						},
						992: {
							items: 2
						}
					}
				});
				$('.manager-info-modal').on('click', function () {
					var video_src = $(this).data('video-url');
					var is_yt = $(this).data('is-yt');
					var embed_code = $(this).data('embed-code');
					if (is_yt === 0) {
						$(document).find('#ManageInfoModal .modal-body').html('<video id="ManagerInfoVid" controls autoplay> <source src="' + video_src + '" type="video/mp4"></video>');
					} else {
						$(document).find('#ManageInfoModal .modal-body').html('<div class="youtube-container">' + embed_code + '</div>');
					}
				});

				$('body').on('click', '.th-additional-details-toggle', function () {
					var el = $(this),
						btn_text = el.find('.plus-sign').text();

					el.find('.plus-sign').text('+' === btn_text ? '-' : '+');
					el.parents('table').find('.th-additional-details').fadeToggle();
				});
				if ($(window).width() < 768) {
					$('.asa-item').slice(0, 6).show();
				}
				$(window).resize(function () {
					if ($(this).width() < 768) {
						$('.asa-item').slice(0, 6).show();
					}
				});
				$("#loadmore").on('click', function (e) {
					e.preventDefault();
					var remaining = $("div.asa-item:hidden").length;
					$("#loadmore-container").remove();
					$("div.asa-item:hidden").slice(0, remaining).fadeIn(1000);

				});

				// Video Modal Container
				var featuredVideoSrc = $('#featuredVideoModal iframe').attr('src');
				$('#featuredVideoModal').on('show.bs.modal', function () {
					// on opening the modal
					// set the video to autostart
					$("#featuredVideoModal iframe").attr("src", featuredVideoSrc + "?autoplay=1&rel=0");
				});
				// on closing the modal
				$('#featuredVideoModal').on('hidden.bs.modal', function (e) {
					// stop the video
					$("#featuredVideoModal iframe").attr("src", null);
				});

				$('#ManageInfoModal').on('show.bs.modal', function () {
					var manageInfoVideoSrc = $('body').find('#ManageInfoModal .youtube-container iframe').attr('src');
					// on opening the modal
					// set the video to autostart
					$("#ManageInfoModal iframe").attr("src", manageInfoVideoSrc + "?autoplay=1&rel=0");
				});

				$("#ManageInfoModal").on('hidden.bs.modal', function (e) {
					// on closing the modal
					// stop the video
					$("#ManageInfoModal iframe").attr("src", null);
				});

			}
		},

		// Store Locations
		'page_template_template_store_location': {
			init: function () {
				$('body').on('click', '.th-additional-details-toggle', function () {
					var el = $(this),
						btn_text = el.find('.plus-sign').text();

					el.find('.plus-sign').text('+' === btn_text ? '-' : '+');
					el.parents('table').find('.th-additional-details').fadeToggle();
				});
			}
		},

		// Promotions Single/Details page.
		'single_promotions': {
			init: function () {
				$('.term-conditions-content').hide();
				$('.term-conditions-title').click(function () {
					$(this).toggleClass('active').next().slideToggle('fast');
				});

				$(window).on('load', function () {
					var pns = $('#promotion-nearby-store');
					showLoader('#promotion-nearby-store');

					if (navigator.geolocation) {
						navigator.geolocation.getCurrentPosition(function (position) {
							var data = {
								lat: position.coords.latitude,
								lng: position.coords.longitude,
								action: 'promotion_get_nearest_store'
							};

							$.ajax({
								url: ajax.url,
								type: 'GET',
								data: data,
								dataType: 'json',
								success: function (html) {
									if (html) {
										pns.html(html);
									} else {
										pns.fadeOut();
									}
								},
								complete: function () {
									pns.unblock();
								}
							});

						}, function () {
							pns.fadeOut();
						});
					} else {
						pns.fadeOut();
					}
				});

				$('body').on('click', '.th-additional-details-toggle', function () {
					var el = $(this),
						btn_text = el.find('.plus-sign').text();

					el.find('.plus-sign').text('+' === btn_text ? '-' : '+');
					el.parents('table').find('.th-additional-details').fadeToggle();
				});
			}
		},

		// Services child pages
		'page_template_template_services_landing': {
			init: function () {
				$(window).on('load', function () {
					var pns = $('#promotion-nearby-store');
					showLoader('#promotion-nearby-store');

					if (navigator.geolocation) {
						navigator.geolocation.getCurrentPosition(function (position) {
							var data = {
								lat: position.coords.latitude,
								lng: position.coords.longitude,
								action: 'promotion_get_nearest_store'
							};

							$.ajax({
								url: ajax.url,
								type: 'GET',
								data: data,
								dataType: 'json',
								success: function (html) {
									if (html) {
										pns.html(html);
									} else {
										pns.fadeOut();
									}
								},
								complete: function () {
									pns.unblock();
								}
							});

						}, function () {
							pns.fadeOut();
						});
					} else {
						pns.fadeOut();
					}
				});

				$('body').on('click', '.th-additional-details-toggle', function () {
					var el = $(this),
						btn_text = el.find('.plus-sign').text();

					el.find('.plus-sign').text('+' === btn_text ? '-' : '+');
					el.parents('table').find('.th-additional-details').fadeToggle();
				});
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function (func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function () {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
